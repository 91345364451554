import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Progress from "../Components/Progress";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import apiUrl from "../api";
import { Box } from "@material-ui/core";
import ReactTable from "react-table";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CustomSweet from "../Components/CustomSweet";
import { currencySet } from "utils/currencyList";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

const style = {
  boxStyle: {
    padding: "40px",
    textAlign: "center",
  },
};

const paymentGatewayList = [
  { name: "Manual", value: "Manual" },
  { name: "Bitcoinvn", value: "Bitcoinvn" },
  { name: "Bloomremit", value: "Bloomremit" },
  { name: "DeeMoney", value: "DeeMoney" },
  { name: "Emqservice", value: "Emqservice" },
  { name: "Ezipay", value: "Ezipay" },
  { name: "InstantCash", value: "InstantCash" },
  { name: "Interac", value: "Interac" },
  { name: "Intervas", value: "Intervas" },
  { name: "Moneywave", value: "Moneywave" },
  { name: "More", value: "More" },
  { name: "Onafriq", value: "Onafriq" },
  { name: "Paga", value: "Paga" },
  { name: "Pipit", value: "Pipit" },
  { name: "Roxe", value: "Roxe" },
  { name: "RoxeIpay", value: "RoxeIpay" },
  { name: "RoxeNium", value: "RoxeNium" },
  { name: "Razorpay", value: "Razorpay" },
  { name: "Thunes", value: "Thunes" },
  { name: "Terrapay", value: "Terrapay" },
  { name: "Walletapi", value: "Walletapi" }
];

const manual_gateway = ['Manual', 'Bitcoinvn', 'DeeMoney', 'Ezipay', 'Interac', 'Intervas', 'Moneywave', 'Paga', 'Roxe', 'RoxeIpay', 'RoxeNium', 'Razorpay', 'Pipit', 'Walletapi', 'Terrapay']

const display_api_rate = {
  Roxe: ['php']
}

const MANUAL = "Manual";
const BITCOINVN = "Bitcoinvn";
const BLOOMREMIT = "Bloomremit";
const DEEMONEY = "DeeMoney";
const EMQSERVICE = "Emqservice";
const EZIPAY = "Ezipay";
const INSTANTCASH = "InstantCash";
const INSTARAILS = "Instarails";
const INTERAC = "Interac";
const INTERVAS = "Intervas";
const MONEYWAVE = "Moneywave";
const MORE = "More";
const ONAFRIQ = "Onafriq";
const PAGA = "Paga";
const PIPIT = "Pipit";
const ROXE = "Roxe";
const ROXEIPAY = "RoxeIpay";
const ROXENIUM = "RoxeNium";
const RAZORPAY = "Razorpay"; 
const THUNES = "Thunes";
const TERRAPAY = "Terrapay";
const WALLETAPI = "Walletapi";

const urlListPaymentGatewayWise = {
  Manual: {
    url: "/get_manual_exchange_rates.json"
  },
  Bitcoinvn: {
    url: "/get_bitcoinvn_exchange_rates.json",
  },
  Bloomremit: {
    url: "/get_bloomremit_exchange_rates.json",
  },
  DeeMoney: {
    url: "/get_deemoney_exchange_rates.json"
  },
  Emqservice: {
    url: "/get_emqservice_exchange_rates.json",
  },
  Ezipay: {
    url: "/get_ezipay_exchange_rates.json",
  },
  InstantCash: {
    url: "/get_instantcash_exchange_rates.json"
  },
  Instarails: {
    url: "/get_instarails_exchange_rates.json"
  },
  Interac: {
    url: "/get_interac_exchange_rates.json",
  },
  Intervas: {
    url: "/get_intervas_exchange_rates.json",
  },
  Moneywave: {
    url: "/get_moneywave_exchange_rates.json"
  },
  More: {
    url: "/get_more_exchange_rates.json"
  },
  Onafriq: {
    url: "/get_onafriq_exchange_rates.json"
  },
  Paga: {
    url: "/get_paga_exchange_rates.json"
  },
  Pipit: {
    url: "/get_pipit_exchange_rates.json"
  },
  Roxe: {
    url: "/get_roxe_exchange_rates.json"
  },
  RoxeIpay: {
    url: "/get_roxeipay_exchange_rates.json"
  },
  RoxeNium: {
    url: "/get_roxenium_exchange_rates.json"
  },
  Razorpay: {
    url: "/get_razorpay_exchange_rates.json"
  },
  Thunes: {
    url: "/get_thunes_exchange_rates.json"
  },
  Terrapay: {
    url: "/get_terrapay_exchange_rates.json"
  },
  Walletapi: {
    url: "/get_walletapi_exchange_rates.json"
  }
};

class CommonExchangeRateView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentGateway: "Roxe",
      sourceCurrencyList: [],
      sourceCurrency: "",
      allCurrencies: [],
      loading: false,
      exchangeRate: [], //this is for holding exchange rate data directly returned by api
      selectedCurrencyExchangeRate: [], //this is for holding filtered exchange rate according to currency with customised params
      exchangeRateCommission: [], // this is for holding commision value
      excahngeRateValues: {},
      totalApiCall: 0,
      selectedBA: "",
      baUsers: [],
    };
    this.reactTableColumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      // {
      //   accessor: "name",
      //   Header: "Name",
      // },
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Exchange Rate",
        accessor: "rate",
      },
      {
        Header: "Commission Percentage",
        accessor: "commission",
      },
      {
        Header: "Commission Rate",
        accessor: "commissionRate",
      },
      // {
      //   Header: "System Rate",
      //   accessor: "systemRate",
      // },
      {
        Header: "Updated At Date",
        accessor: "date",
      },
      {
        Header: "Updated At Time",
        accessor: "time",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }

  componentDidMount() {
    this.getAllCurrencies();
    if (userType !== SUPERADMINUSER) {
      this.getExchangeRate();
    }else{
      this.getAllBaUsers();
    }
  }

  handleSourceCurrencyChange = (event) => {
    const currentSelectedCurrency = this.state.sourceCurrency;
    const inputCurrency = event.target.value;

    if (currentSelectedCurrency !== inputCurrency) {
      this.setState({
        sourceCurrency: event.target.value,
        loading: true,
      });
    }
  };

  handlePaymentGatewayChange = (event) => {
    const { paymentGateway: currentPaymentGteway } = this.state;
    const inputPaymentGateway = event.target.value;
    let allowLoading = this.state.selectedBA != '' ? true : false

    if (currentPaymentGteway !== inputPaymentGateway) {
      this.setState({
        paymentGateway: inputPaymentGateway,
        loading: allowLoading,
        sourceCurrency: "",
      });
    }
  };

  handeleBaChange = (event) => {
    const { value } = event.target;
    this.setState({
      selectedBA: value,
    });
  };

  handleCommisionChange = (event) => {
    const { name, value } = event.target;
    const { excahngeRateValues } = this.state;
    const existingValue = excahngeRateValues[name];
    const data = existingValue
      ? { [name]: { ...existingValue, value: value } }
      : { [name]: { value: value } };
    this.setState({
      excahngeRateValues: { ...this.state.excahngeRateValues, ...data },
    });
  };

  handleManualExchangeRateChange = (event) => {
    const { name, value } = event.target;
    const { excahngeRateValues } = this.state;
    const existingValue = excahngeRateValues[name];

    const data = existingValue
      ? { [name]: { ...existingValue, rate: value } }
      : { [name]: { rate: value } };
    this.setState({
      excahngeRateValues: { ...this.state.excahngeRateValues, ...data },
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      sourceCurrency,
      totalApiCall,
      paymentGateway,
      selectedBA,
    } = this.state;
    const {
      sourceCurrency: prevSourceCurrency,
      totalApiCall: prevTotalApiCall,
      paymentGateway: prevPaymentGateway,
      selectedBA: prevSelectedBA,
    } = prevState;
    if (prevSourceCurrency !== sourceCurrency && sourceCurrency !== "") {
      // this.getExchangeRate(this.state.sourceCurrency);
      this.setStateDataAccordingToPaymentGateway();
      this.getExchangeRateCommission(this.state.sourceCurrency);
    }
    if (prevTotalApiCall !== totalApiCall) {
      const loading = totalApiCall > 0;
      this.setState({
        loading,
      });
    }
    if (paymentGateway !== prevPaymentGateway && userType !== SUPERADMINUSER) {
      // this.setStateDataAccordingToPaymentGateway();
      this.getExchangeRate();
    } else if (paymentGateway !== prevPaymentGateway && selectedBA !== "") {
      this.getExchangeRate();
    }
    if (userType === SUPERADMINUSER && selectedBA !== prevSelectedBA) {
      this.getExchangeRate();
    }
  }

  getExchangeRateCommission = (currency = "usd") => {
    this.setState((state) => ({
      totalApiCall: state.totalApiCall + 1,
    }));
    const { paymentGateway } = this.state;
    const params = userType === SUPERADMINUSER ? {ba_id:this.state.selectedBA}:{};
    axios({
      method: "get",
      url:
        base_url + `/exchange_commission_rates.json?currency_input=${currency}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        payment_gateway: paymentGateway,
        ...params,
      },
    })
      .then((result) => {
        //access the results here....
        const exchangeRateCommission = result.data.exchange_commission_rates;
        const excahngeRateValues = this.getExchangeRateValueObject(
          exchangeRateCommission
        );

        this.setState((state) => ({
          exchangeRateCommission,
          excahngeRateValues,
          loading: false,
          totalApiCall: state.totalApiCall - 1 < 0 ? 0 : state.totalApiCall - 1,
        }));
      })
      .catch((error) => {
        console.log(error);
        this.setState((state) => ({
          totalApiCall: state.totalApiCall - 1 < 0 ? 0 : state.totalApiCall - 1,
        }));
      });
  };

  getExchangeRateValueObject = (exchangeRateCommission = []) => {
    const excahngeRateValues = {};
    //  console.log(exchangeRateCommission);
    for (let element of exchangeRateCommission) {
      excahngeRateValues[element.currency_output] = {
        value: Number(element.commission_percentage),
        id: element.id,
        rate: Number(element.exchange_rate),
        commissionRate: element.commission_rate,
        updatedAt: element.updated_at,
        systemRate: element.exchange_rate,
      };
    }
    return excahngeRateValues;
  };

  getExchangeRate = () => {
    const { paymentGateway } = this.state;
    this.setState((state) => ({
      totalApiCall: state.totalApiCall + 1,
    }));
    const params =
      userType === SUPERADMINUSER ? { ba_id: this.state.selectedBA } : {};
    axios({
      method: "get",
      url: base_url + `${urlListPaymentGatewayWise[paymentGateway].url}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: params,
    })
      .then((result) => {
        //access the results here....
        const exchangeRate = result.data.currency_info;
        this.setState((state) => ({
          exchangeRate,
          // tableRows: this.getRowData(exchangeRate),
          totalApiCall: state.totalApiCall - 1 < 0 ? 0 : state.totalApiCall - 1,
        }));
      })
      .catch((error) => {
        console.log(error);
        this.setState((state) => ({
          totalApiCall: state.totalApiCall - 1 < 0 ? 0 : state.totalApiCall - 1,
        }));
      });
  };

  setStateDataAccordingToPaymentGateway = () => {
    const { paymentGateway } = this.state;
    if (paymentGateway === BITCOINVN) {
      this.setState({
        selectedCurrencyExchangeRate: this.filterDataforBitcoinvn(),
      });
    } else if (paymentGateway === BLOOMREMIT) {
      this.setState({
        selectedCurrencyExchangeRate: this.filterDataForBloomremit(),
      });
    } else if (paymentGateway === EMQSERVICE) {
      this.setState({
        selectedCurrencyExchangeRate: this.filterDataForEmq(),
      });
    } else if (paymentGateway === INSTARAILS) {
      this.setState({
        selectedCurrencyExchangeRate: this.filterDataForInstarails(),
      });
    } else if (paymentGateway === MORE) {
      this.setState({
        selectedCurrencyExchangeRate: this.filterDataForMore(),
      });
    } else if (paymentGateway === ONAFRIQ) {
      this.setState({
        selectedCurrencyExchangeRate: this.filterDataForOnafriq(),
      });
    } else if (paymentGateway === THUNES) {
      this.setState({
        selectedCurrencyExchangeRate: this.filterDataForThunes(),
      });
    } else if (paymentGateway === INSTANTCASH) {
      this.setState({
        selectedCurrencyExchangeRate: this.filterDataForInstantCash(),
      });
    } else if (manual_gateway.includes(paymentGateway)) {
      this.setState({
        selectedCurrencyExchangeRate: this.filterDataForManual(),
      });
    }
    // else if (paymentGateway === TERRAPAY) {
      //get exchange rate from api
      // this.setState({
      //   selectedCurrencyExchangeRate: this.filterDataForTerrapay(),
      // });
    // }
  };

  filterDataForBloomremit = () => {
    const { exchangeRate, sourceCurrency: selectedCurrency } = this.state;
    let pattern = new RegExp("^" + selectedCurrency, "i");
    const selectedElements = Object.keys(exchangeRate)
      .filter((element) => {
        return pattern.test(element);
      })
      .reduce((array, key) => {
        const currency = key.substring(selectedCurrency.length);
        if (currencySet.has(currency)) {
          const obj = {
            destination_currency: currency,
            rate: exchangeRate[key],
          };
          array.push(obj);
        }
        return array;
      }, []);
    return selectedElements;
  };

  filterDataForEmq = () => {
    const { sourceCurrency, exchangeRate } = this.state;
    const filteredData = exchangeRate
      .filter((data) => {
        return data.source_currency === sourceCurrency;
      })
      .map((data) => {
        return {
          destination_currency: data.destination_currency,
          rate: Number(data.rate),
        };
      });
    return filteredData;
  };

  filterDataForInstarails = () => {
    const { sourceCurrency, exchangeRate } = this.state;
    const filteredData = exchangeRate
      .filter((data) => {
        return data.source_currency.toLowerCase() === sourceCurrency;
      })
      .map((data) => {
        return {
          destination_currency: data.destination_currency.toLowerCase(),
          rate: Number(data.rate),
        };
      });
    return filteredData;
  };

  filterDataForOnafriq = () => {
    const { sourceCurrency, exchangeRate } = this.state;
    const filteredData = exchangeRate
      .filter((data) => {
        return data.source_currency.toLowerCase() === sourceCurrency;
      })
      .map((data) => {
        return {
          destination_currency: data.destination_currency.toLowerCase(),
          rate: Number(data.rate),
        };
      });
    return filteredData;
  };

  filterDataForThunes = () => {
    const { sourceCurrency, exchangeRate } = this.state;
    const filteredData = exchangeRate
      .filter((data) => {
        return data.source_currency.toLowerCase() === sourceCurrency;
      })
      .map((data) => {
        return {
          destination_currency: data.destination_currency.toLowerCase(),
          rate: Number(data.rate),
        };
      });
    return filteredData;
  };

  filterDataForTerrapay = () => {
    const { sourceCurrency, exchangeRate } = this.state;
    console.log(exchangeRate)
    const filteredData = exchangeRate.quotes
      .map((data) => {
        return {
          destination_currency: data.receivingCurrency,
          rate: Number(data.fxRate),
        };
      });
    return filteredData;
  };

  filterDataforBitcoinvn = () => {
    const { sourceCurrency, exchangeRate } = this.state;
    const filteredData = exchangeRate
      .filter((data) => {
        return data.currency_input.toLowerCase() === sourceCurrency;
      })
      .map((data) => {
        return {
          destination_currency: data.currency_output,
          rate: Number(data.exchange_rate),
        };
      });
    return filteredData;
  };

  filterDataForMore = () => {
    const { sourceCurrency, exchangeRate } = this.state;
    const filteredData = exchangeRate
      .filter((data) => {
        return data.source_currency.toLowerCase() === sourceCurrency;
      })
      .map((data) => {
        return {
          destination_currency: data.destination_currency.toLowerCase(),
          rate: Number(data.rate),
        };
      });
    return filteredData;
  };

  filterDataForInstantCash = () => {
    const { sourceCurrency, exchangeRate } = this.state;
    const filteredData = exchangeRate
      .filter((data) => {
        return data.source_currency.toLowerCase() === sourceCurrency;
      })
      .map((data) => {
        return {
          destination_currency: data.destination_currency.toLowerCase(),
          rate: Number(data.rate),
        };
      });
    return filteredData;
  };

  filterDataForManual = () => {
    const { sourceCurrency, exchangeRate } = this.state;
    const updatedData = exchangeRate
      .filter((data) => data.currency_input.toLowerCase() === sourceCurrency)
      .map((data) => {
        return { destination_currency: data.currency_output, rate: Number(data.exchange_rate) };
      });
    return updatedData;
  };

  getInputElement = (name = "", value) => {
    return (
      <CustomInput
        labelText="Value"
        inputProps={{
          type: "number",
          value: value,
          onChange: this.handleCommisionChange,
          name: name,
        }}
      />
    );
  };

  getManualRateInputElement = (name = "", value, row, payment_gateway) => {
    return (
      <CustomInput
        labelText="Exchange Rate"
        inputProps={{
          type: "number",
          value: value,
          onChange: this.handleManualExchangeRateChange,
          name: name,
        }}
      />
    );
  };

  handleUpdateOrSubmitCommission = (
    id,
    destinationCurrency,
    exchangeRate,
    value
  ) => {
    const { sourceCurrency, paymentGateway } = this.state;
    const method = id ? "put" : "post";
    const message = id ? "updated" : "created";
    const url = id
      ? base_url + "/exchange_commission_rates/" + id + ".json"
      : base_url + "/exchange_commission_rates.json";
    this.setState({ loading: true });
    const params =
      userType === SUPERADMINUSER
        ? { "exchange_commission_rate[ba_id]": this.state.selectedBA }
        : {};
    axios({
      method: method,
      url: url,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
      params: {
        "exchange_commission_rate[currency_input]": sourceCurrency,
        "exchange_commission_rate[currency_output]": destinationCurrency,
        "exchange_commission_rate[commission_percentage]": value,
        "exchange_commission_rate[exchange_rate]": exchangeRate,
        "exchange_commission_rate[payment_gateway]": paymentGateway,
        ...params,
      },
    })
      .then((result) => {
        // console.log(result.data);
        const { data } = result;
        const param = data.exchange_commission
          ? data.exchange_commission
          : data;
        this.setState({
          loading: false,
          alert: true,
          alert_msg: `Currency Conversion successfully ${message}.!`,
          alert_title: "Currency Conversion!",
          danger: false,
          warning: false,
          success: true,
          excahngeRateValues: this.getUpdatedExchangeComissionValues(param),
          // conversions: this.updateDataOrCreateData(method, result.data),
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          alert: true,
          alert_msg: "Something Went Wrong !!",
          alert_title: "Error!",
          danger: true,
          warning: false,
          success: false,
        });
      });
  };

  getActionButton = (id, destinationCurrency, exchangeRate) => {
    const { excahngeRateValues } = this.state;
    const { value } = excahngeRateValues[destinationCurrency]
      ? excahngeRateValues[destinationCurrency]
      : { value: "" };

    const disabled = (value === undefined || value === "") || (exchangeRate === undefined || exchangeRate === "")


    return (
      <Button
        variant="contained"
        size="sm"
        disabled={disabled}
        onClick={() => {
          this.handleUpdateOrSubmitCommission(
            id,
            destinationCurrency,
            exchangeRate,
            value
          );
        }}
      >
        {id ? "update" : "submit"}
      </Button>
    );
  };

  getUpdatedExchangeComissionValues = (data) => {
    const { excahngeRateValues } = this.state;
    const updatedExchageRateValues = { ...excahngeRateValues };
    updatedExchageRateValues[data.currency_output] = {
      value: data.commission_percentage,
      rate: data.exchange_rate,
      id: data.id,
      commissionRate: data.commission_rate,
      updatedAt: data.updated_at,
    };
    return updatedExchageRateValues;
  };

  getRowData = (rows = []) => {
    const { excahngeRateValues } = this.state;
    const {paymentGateway} = this.state;
    return rows.map((row, index) => {
      const name = row.destination_currency.toLowerCase();
      const data = excahngeRateValues[name];
      const { val, id, commissionRate, timeStamp, systemRate, rate } = data
        ? {
            val: data.value,
            id: data.id,
            commissionRate: data.commissionRate,
            timeStamp: data.updatedAt,
            systemRate: data.systemRate,
            rate: data.rate
          }
        : {val: "", rate: ""};

      const { updateDate, updateTime } = this.getDateAndTime(timeStamp);
      return {
        id: index + 1,
        // name: row.name,
        code: this.displayApiRate(paymentGateway, row.destination_currency, row.rate),
        rate: manual_gateway.includes(paymentGateway) ? this.getManualRateInputElement(name, rate, row, paymentGateway) : row.rate.toFixed(6),
        commission: this.getInputElement(name, val),
        commissionRate: commissionRate ? commissionRate : "NA",
        systemRate: systemRate ? systemRate : "NA",
        date: updateDate,
        time: updateTime,
        action: manual_gateway.includes(paymentGateway) ? this.getActionButton(id, name, rate) : this.getActionButton(id, name, row.rate)
      };
    });
  };

  displayApiRate = (pg, currency, rate) => {
    if(display_api_rate[pg]){
      let api_rate = display_api_rate[pg].includes(currency);
      let curr_txt = currency;

      if(api_rate){
        curr_txt = curr_txt + ' ( ' + rate.toFixed(2) + ' )'
      }

      return curr_txt;
    }
    else{
      return currency;
    }
  }

  getDateAndTime = (date = "NA") => {
    let updatedDate = new Date(date);

    if (updatedDate.toDateString() !== "Invalid Date") {
      let updateDate =
        updatedDate.getFullYear() +
        "-" +
        (updatedDate.getMonth() + 1) +
        "-" +
        updatedDate.getDate();
      let updateTime =
        updatedDate.getHours() +
        ":" +
        updatedDate.getMinutes() +
        ":" +
        updatedDate.getSeconds();

      return { updateDate, updateTime };
    }
    return { updateDate: "NA", updateTime: "NA" };
  };

  getAllCurrencies = () => {
    axios({
      method: "get",
      url: base_url + "/currencies.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    })
      .then((result) => {
        //access the results here....
        const currencies = result.data.currencies;
        const sourceCurrencyList = this.filterCurrencySourceDestination(
          currencies
        );
        this.setState({
          allCurrencies: currencies,
          sourceCurrencyList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterCurrencySourceDestination = (currencies = []) => {
    return currencies.filter((data) => {
      return data.attributes.type !== "destination";
    });
  };

  hideAlert = () => {
    this.setState({
      alert: false,
      alert_msg: "",
      alert_title: "",
      alert_type: "",
    });
  };

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  getBAUserList = () => {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
        <GridItem xs={12} sm={12} md={3}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
                disabled={this.state.loading}
              >
                <InputLabel className={classes.selectLabel}>
                  {"BA *"}
                </InputLabel>

                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.selectedBA}
                  onChange={this.handeleBaChange}
                  inputProps={{
                    name: "selectedBA",
                  }}
                >
                  {this.state.baUsers.map((row) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={row.id}
                      key={row.id}
                    >
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
        </GridItem>
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    const {
      selectedCurrencyExchangeRate,
      sourceCurrency,
      paymentGateway,
    } = this.state;
    const tableRows = this.getRowData(selectedCurrencyExchangeRate);
    return (
      <>
        <CustomSweet
          alert={this.state.alert}
          message={this.state.alert_msg}
          title={this.state.alert_title}
          hide_alert={this.hideAlert}
          danger={this.state.danger}
          warning={this.state.warning}
          success={this.state.success}
          times={this.state.times}
        />
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Exchange Rate</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <GridContainer justify="center">
                    {this.getBAUserList()}
                    <GridItem xs={12} md={3} sm={10}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        disabled={this.state.loading}
                      >
                        <InputLabel className={classes.selectLabel}>
                          {"Payment Gateway *"}
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.paymentGateway}
                          onChange={this.handlePaymentGatewayChange}
                          inputProps={{
                            name: "paymentGateway",
                          }}
                        >
                          {paymentGatewayList.map((row) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={row.value}
                              key={row.value}
                            >
                              {row.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} md={3} sm={10}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        disabled={this.state.loading || (userType === SUPERADMINUSER && this.state.selectedBA==="")}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          {"Source Currency *"}
                        </InputLabel>

                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={this.state.sourceCurrency}
                          onChange={this.handleSourceCurrencyChange}
                          inputProps={{
                            name: "simpleSelect",
                          }}
                        >
                          {this.state.sourceCurrencyList.map((row) => (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={row.attributes.name}
                              key={row.attributes.id}
                            >
                              {row.attributes.name.toUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>
                      {paymentGateway} Exchange Rate Table
                    </h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  {sourceCurrency ? (
                    <ReactTable
                      data={tableRows}
                      columns={this.reactTableColumns}
                      defaultPageSize={10}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                    ></ReactTable>
                  ) : (
                    <Box component="div" className={classes.boxStyle}>
                     {userType === SUPERADMINUSER && this.state.selectedBA===""?"Please Select BA !!!":"Please Select Currency !!!"} 
                    </Box>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </>
    );
  }
}

export default withStyles({ ...cssstyles, ...style })(CommonExchangeRateView);
