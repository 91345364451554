import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import moment from "moment";
import apiUrl from "../api";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Progress from "../Components/Progress";
import { makeApiCall } from "utils/commonCheckFunctions";

const base_url = apiUrl;
const userType = localStorage.getItem("user_type");
const SUPERADMINUSER = "superadmin";

let d = new Date();
d.setDate(d.getDate() - 1);
d.setHours(0);
d.setMinutes(0);
d.setSeconds(1);
let prev_date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
//   " "
// let prev_time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

let tempDate = new Date();
tempDate.setHours(23);
tempDate.setMinutes(59);
tempDate.setSeconds(59);
let date =
  tempDate.getFullYear() +
  "-" +
  (tempDate.getMonth() + 1) +
  "-" +
  tempDate.getDate();

const paymentGatewayList = [
  { name: "Manual", value: "Manual" },
  { name: "Roxe", value: "Roxe" },
  { name: "RoxeNium", value: "RoxeNium" },
  { name: "RoxeIpay", value: "RoxeIpay" },
  { name: "Transferzero", value: "Transferzero" },
  { name: "Bloomremit", value: "Bloomremit" },
  { name: "Emqservice", value: "Emqservice" },
  { name: "Dapspay", value: "Dapspay" },
  { name: "Terrapay", value: "Terrapay" },
  { name: "Onafriq", value: "Onafriq" },
  { name: "Thunes", value: "Thunes" },
  { name: "More", value: "More" },
  { name: "InstantCash", value: "InstantCash" },
  { name: "DeeMoney", value: "DeeMoney" }
];

class ExchangeCommissionLogsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: 0,
      selectedDate: prev_date,
      selectedEndDate: date,
      sourceCurrencyList: [],
      destinationCurrencyList: [],
      sourceCurrency: "",
      destinationCurrency: "",
      allCurrencies: [],
      commissionLogs: [],
      selectedBA:"",
      baUsers:[],
      loading: false,
      paymentGateway: "Roxe",
    };
  }

  componentDidMount() {
    this.getAllCurrencies();
    this.getDestinationCurrencies();
    if(userType === SUPERADMINUSER){
      this.getAllBaUsers();
    }
  }

  getDestinationCurrencies = () => {
    axios({
      method: "get",
      url: base_url + `/show_walletable_currencies.json`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    })
      .then((result) => {
        //access the results here....
        const destinationCurrencyList = result.data.currencies;
        this.setState({
          destinationCurrencyList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAllCurrencies = () => {
    axios({
      method: "get",
      url: base_url + "/currencies.json",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("session_token")}`,
      },
    })
      .then((result) => {
        //access the results here....
        const currencies = result.data.currencies;
        const sourceCurrencyList = this.filterCurrencySourceDestination(
          currencies
        );
        this.setState({
          allCurrencies: currencies,
          sourceCurrencyList,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  filterCurrencySourceDestination = (currencies = []) => {
    return currencies.filter((data) => {
      return data.attributes.type !== "destination";
    });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ selectedEndDate: date });
  };

  handleDestinationChange = (event) => {
    this.setState({ destinationCurrency: event.target.value });
  };

  handleSourceChange = (event) => {
    this.setState({ sourceCurrency: event.target.value });
  };
  handlePaymentGatewayChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  onSearch = (event) => {
    event.preventDefault();
    // const base_url = window.location.origin;
    const isDataValid = this.validateData();
    if (isDataValid) {
      const startDate = moment(this.state.selectedDate).format("YYYY-MM-DD");
      const endDate = moment(this.state.selectedEndDate).format("YYYY-MM-DD");
      const {selectedBA} = this.state;
      const params = userType === SUPERADMINUSER ?{ba_id:selectedBA}:{};
      this.setState({ loading: true });
      axios({
        method: "get",
        url: base_url + "/show_exchange_commission_timelogs.json",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          currency_input: this.state.sourceCurrency,
          currency_output: this.state.destinationCurrency,
          payment_gateway: this.state.paymentGateway,
          ...params,
        },
      })
        .then((result) => {
          if (result.data) {
            this.setState({
              commissionLogs: result.data.exchange_time_logs,
              loading: false,
            });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          this.setState({
            show_error: true,
            error_message: error.response.data
              ? error.response.data.message
              : error.message,
            loading: false,
          });
        });
    }
  };

  validateData = () => {
    const { selectedDate, selectedEndDate, selectedBA } = this.state;
    if (selectedDate === "") {
      alert("Please enter start date !!!");
      return false;
    }
    if (!this.isDateValid(selectedDate)) {
      alert("Please enter valid start date !!!");
      return false;
    }
    if (selectedEndDate === "") {
      alert("Please enter end date !!!");
      return false;
    }
    if (!this.isDateValid(selectedEndDate)) {
      alert("Please enter valid end date !!!");
      return false;
    }

    if(selectedBA ==="" && userType === SUPERADMINUSER){
      alert("Please enter BA !!!");
      return false;
    }
    return true;
  };

  isDateValid = (date) => {
    return moment(date).isValid();
  };
  get getData() {
    return this.state.commissionLogs.map((row) => ({
      id: row.id,
      userName: row.user_name,
      currencyInput: row.currency_input,
      currencyOutput: row.currency_output,
      commissionPercentage: row.commission_percentage,
      created_at: row.created_at,
      created_time: row.created_time,
    }));
  }
  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
      : true;
  };

  getSourceCurrency = (classes = {}) => {
    const { sourceCurrencyList } = this.state;

    return sourceCurrencyList.map((row) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={row.attributes.name}
          key={row.attributes.id}
        >
          {row.attributes.name.toUpperCase()}
        </MenuItem>
      );
    });
  };

  getDestinationCurrency = (classes = {}) => {
    const { destinationCurrencyList } = this.state;
    return destinationCurrencyList.map((element) => {
      const { attributes: row } = element;
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}
          value={row.name.toLowerCase()}
          key={row.id}
        >
          {row.name.toUpperCase()}
        </MenuItem>
      );
    });
  };

  getPaymentGateway = (classes = {}) => {
    return (
      <GridItem xs={12} md={3} sm={12}>
        <Card>
          <CardBody>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}>
                {"Payment Gateway *"}
              </InputLabel>

              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.paymentGateway}
                onChange={this.handlePaymentGatewayChange}
                inputProps={{
                  name: "paymentGateway",
                }}
              >
                {paymentGatewayList.map((row) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={row.value}
                    key={row.value}
                  >
                    {row.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardBody>
        </Card>
      </GridItem>
    );
  };

  handeleBaChange = (event)=>{
   const {name,value} = event.target;
   this.setState({
     [name]:value,
   });
  }

  getAllBaUsers = async () => {
    const data = await makeApiCall({
      url: "/users.json",
      method: "get",
      params: { role: "ba" },
    });
    const { error = false } = data;
    if (!error) {
      this.setState({
        baUsers: data,
      });
    } else {
      const { message } = data;
      console.log(message);
    }
  };

  getBAUserList = () => {
    const { classes } = this.props;
    if (userType === SUPERADMINUSER) {
      return (
        <GridItem xs={12} sm={12} md={3}>
          <Card>
                <CardBody style={{ padding: "10px 20px" }}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel className={classes.selectLabel}>
                  {"BA *"}
                </InputLabel>

                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.selectedBA}
                  onChange={this.handeleBaChange}
                  inputProps={{
                    name: "selectedBA",
                  }}
                >
                  {this.state.baUsers.map((row) => (
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={row.id}
                      key={row.id}
                    >
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </CardBody>
              </Card>
        </GridItem>
      );
    } else {
      return null;
    }
  };

  render() {
    const { classes } = this.props;
    const timelogData = this.getData;
    const { paymentGateway } = this.state;
    return (
      <div>
        <div className={this.state.loading === true ? classes.main_screen : ""}>
          <div align="center">
            {this.state.loading === true && <Progress />}
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <InputLabel className={classes.label}>Start Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "Start Date" }}
                      id="start-date-picker-dialog"
                      label="Start Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={this.state.selectedDate}
                      onChange={this.handleDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "5px 20px" }}>
                  <InputLabel className={classes.label}>End Date</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      inputProps={{ placeholder: "End Date" }}
                      id="end-date-picker-dialog"
                      label="End Date"
                      dateFormat={"YYYY-MM-DD"}
                      value={this.state.selectedEndDate}
                      onChange={this.handleEndDateChange}
                    />
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
            {this.getBAUserList()}
            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Source Currency Input
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.sourceCurrency}
                      onChange={this.handleSourceChange}
                      inputProps={{
                        name: "simpleSelect",
                        id: "source-currency-select",
                      }}
                    >
                      {this.getSourceCurrency(classes)}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      Source Currency Output
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={this.state.destinationCurrency}
                      onChange={this.handleDestinationChange}
                      inputProps={{
                        name: "simpleSelect",
                        id: "destination-currency-select",
                      }}
                    >
                      {this.getDestinationCurrency(classes)}
                    </Select>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
          {/* </GridContainer>
          <GridContainer> */}
            {this.getPaymentGateway(classes)}

            <GridItem xs={12} sm={12} md={3}>
              <Card>
                <CardBody style={{ padding: "10px 20px" }}>
                  <FormControl fullWidth>
                    <Button color="rose" onClick={this.onSearch}>
                      Search
                    </Button>
                  </FormControl>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>
                      {paymentGateway} Exchange Commission Logs
                    </h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={timelogData}
                    filterable
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                      },

                      {
                        Header: "User Name",
                        accessor: "userName",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "currencyInput",
                        Header: "Source Currency Input",
                        filterMethod: this.filterMethod,
                      },
                      {
                        accessor: "currencyOutput",
                        Header: "Source Currency Output",
                        filterMethod: this.filterMethod,
                      },

                      {
                        accessor: "commissionPercentage",
                        Header: "Commission Percentage",
                      },
                      {
                        accessor: "created_at",
                        Header: "Created At",
                      },
                      {
                        accessor: "created_time",
                        Header: "Created Time",
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                    // defaultFilterMethod={this.filterMethod}
                  />
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}
export default withStyles(cssstyles)(ExchangeCommissionLogsView);
